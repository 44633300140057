import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import { ValidateCI } from '../utils/validateCi'
import MaskedInput from "react-text-mask";



import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik'



const Reclamos = (props) => {

  const showCrediton = true;
  const assets = 'assets'

  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Setters
  const [loading, setLoading] = React.useState(false);
  const [showToast, setToast] = React.useState(false);
  const [blurredDate, setBlurredDate] = React.useState(false);
  const [mesValue, setMes] = React.useState();
  const [diaValue, setDia] = React.useState();
  const [anoValue, setAno] = React.useState();
  const [activeStep, setActiveStep] = React.useState(1);
  const [isCrediton, setIsCrediton] = React.useState(false);
  const [isCreditonPlus, setIsCreditonPlus] = React.useState(false);


  const [hasMounted, setHasMounted] = React.useState(false);


  //First step
  const [mensaje, setMensaje] = React.useState();
  const [title, setTitle] = React.useState();
  const [wpp, setWpp] = React.useState();

  //Form
  const campoError = 'El campo es requerido'
  var noValidate = false;
  var errors = {};

  //Date validation
  const getDia = (evt) => {

    setDia(evt.target.value)
    setBlurredDate(true);

  }
  const getAno = (evt) => {

    setAno(evt.target.value);
    setBlurredDate(true);

  }
  const getMes = (evt) => {

    setMes(evt.target.value)
    setBlurredDate(true);

  }


  const maxLengthCheck = (object) => {

    if (object.target.value.length == object.target.maxLength) {

      if (object.target.nextSibling) {
        object.target.nextSibling.focus()
      }

    }

    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }

  }

  //CI

  const CIMask = [
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/
  ];


  //Tel

  const TelMask = [
    /[0]/,
    /[9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/
  ];



  useEffect(() => {
    setHasMounted(true);
  }, []);




  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-reclamos'} >


        <SEO metadata={$metadata} title={'Formulario de reclamos'}></SEO>


        <>

          <Helmet>


            <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=11`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=2`)} rel="stylesheet" type="text/css" />

            <link href={withPrefix(`../../${assets}/css/inline/form-inline.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/solicitud.css?v=4`)} rel="stylesheet" type="text/css" />

          <style>

            {`

              #reclamos-form{
                margin-block: 40px
              }


            `}

          </style>

          </Helmet>


          {/* HERO */}
          <div className="c-form-hero">
            <div className="c-form-hero__image">
            </div>{/* .c-hero__holder */}
          </div>{/* .c-hero */}



          <div className="c-viewport o-wrapper">{/*
                    */}{showToast && !loading &&
              <div className="c-toast is-active" onClick={() => { setToast(false) }}>
                <div className="c-toast_holder">
                  <span className="c-toast__icon" />
                  <button className="c-toast__close" onClick={() => { setToast(false) }} />
                  <span className="c-toast__alert"><strong>Error:</strong></span>
                  <span className="c-toast__text">Ha ocurrido un error al enviar el formulario, intente nuevamente.</span>
                </div>
              </div>}{/*
                    */}{loading &&
              <div className="c-viewport__step c-loading">
                <span className="c-loading-animation c-loading-animation--large" />
                <span className="c-loading-text">Cargando...</span>
              </div>}{/*
                    */}{activeStep == 1 && !loading &&
              <div className="c-viewport__step o-section">
                <div className="c-viewport__step-holder">

                  <h2 className={`c-title c-title--small ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>Formulario de reclamos</h2>
                  <span className={`c-viewport__subtitle c-text ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>En CreditON siempre queremos mejorar la calidad de nuestros servicios. Por eso CreditON pone a disposición de sus clientes el siguiente formulario para realizar reclamos, sugerencias y quejas (art. 327 de la RNRCSF). Por favor complete todos los campos con toda la información para permitirnos darle una debida resolución a su reclamo.</span>                      

                  <Formik

                    validateOnChange={false}

                    initialValues={{
                      name: '',
                      lastname: '',
                      dni: '',
                      email: '',
                      message: '',
                    }}

                    onSubmit={
                      (values, actions) => {

                        setLoading(true);

                        values.dni = values.dni.replace(/\D/g, '');
                        values.email = values.email.replaceAll(' ', '');
                        values.email = values.email.trim();

                        /*
                        //Fetch to API
                        (async () => {

                          const rawResponse = await fetch(`https://becredweb.volmet.com.uy/api/v1/loan_simulations`, {
                            method: "POST",
                            headers: {
                              'Accept': "application/json",
                              'Content-Type': "application/json",
                              'Authorization': 'Token token=fc785cfb17ba4c45b6b1'
                            }, withCredentials: true,
                            body: JSON.stringify({ ...values })
                          })

                          //Get API response
                          const response = await rawResponse.json();


                          if (response.status == "OK") {

                            //Patch response data
                            setTitle(response.title);
                            setWpp(response.show_whatsapp);
                            setMensaje(response.message);

                            setActiveStep(2);
                            setLoading(false);

                            //Pixel FB convertion
                            window.fbq('track', 'Solicitud', values);


                          } else {

                            setLoading(false);
                            setToast(true);

                            setTimeout(() => {
                              setToast(false);
                            }, 5000);

                          }

                          


                        })();

                        */

                      }
                    }
                    validate={values => {

                      noValidate = true;

                      errors = {};

                      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


                      //Required
                      Object.keys(values).
                        forEach((id) => {
                          if ((!values[id] || values[id] == "null") && id != "email") {
                            errors[id] = campoError
                          }
                        })

                      //CI
                      if (values.dni && (!ValidateCI(values.dni) || values.dni.length < 7)) {
                        errors.dni = "La cédula no es válida"
                      }

                      //Email      
                      if (values.email && !emailRegex.test(values.email)) {
                        errors.email = "El email no es válido"
                      }


                      return errors;


                    }}
                  >
                    {({ validateForm, setFieldValue, values, handleChange, handleBlur }) => (

                      <Form key="reclamos-form" name="reclamos-form" id="reclamos-form" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" netlify>

                        {((!isCrediton && !showCrediton) || (!isCreditonPlus && showCrediton)) &&

                          <>
                            <input type="hidden" name="form-name" value="reclamos-form" />
                            <input type="hidden" name="bot-field" />

                            <div className="c-form__group">

                              <ErrorMessage name="name" children={() => { return (<label htmlFor="name" className="c-form__error">{errors.name}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Nombre</span>

                                <Field name="name" type="text" className="c-form__input" />


                              </label>


                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="lastname" children={() => { return (<label htmlFor="lastname" className="c-form__error">{errors.lastname}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Apellido</span>

                                <Field name="lastname" type="text" className="c-form__input" />


                              </label>


                            </div>


                            <div className="c-form__group">

                              <ErrorMessage name="dni" children={() => { return (<label htmlFor="dni" className="c-form__error">{errors.dni}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Cédula de Identidad</span>


                                <Field
                                  name="dni"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={CIMask}
                                      placeholder="0.000.000-0"
                                      type="tel"
                                      className="c-form__input"
                                    />
                                  )}
                                />


                              </label>


                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="email" children={() => { return (<label htmlFor="email" className="c-form__error">{errors.email}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Email</span>
                                <Field name="email" type="email" className="c-form__input" placeholder="juan.lopez@crediton.com.uy" />
                              </label>

                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="message" children={() => { return (<label htmlFor="message" className="c-form__error">{errors.message}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Texto del reclamo</span>

                                <Field as="textarea" name="message" type="text" rows="5" className="c-form__input" />


                              </label>


                            </div>


                            <button type="submit" className={`c-btn c-form__btn ${loading ? 'is-loading' : ''} `}> {loading && <span className="c-loading-animation" />}¡Lo quiero ya!</button>
                          </>

                        }


                      </Form>

                    )}

                  </Formik>
                </div>

                <p className={`c-viewport__subtitle c-text ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>Una vez presentado el reclamo, CreditON dispondrá de un plazo de quince (15) días corridos para dar una respuesta al cliente. Dicho plazo podrá prorrogarse por única vez por otros quince día corridos, siempre que la naturaleza del reclamo así lo amerite, en cuyo caso recibirá por escrito los motivos de la prórroga; el nuevo plazo vencerá el día hábil siguiente al vencimiento del plazo, si éste fuere en un día inhábil. La respuesta será enviada a la dirección de correo electrónico provisto por Ud. al completar este formulario.</p>
                <br/>
                <p className={`c-viewport__subtitle c-text ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>CreditON (VOLMET S.A.) es un entidad regulada y supervisada por el Banco Central del Uruguay. Tener presente que en todo momento Ud. tienen la posibilidad de trasladar los reclamos a la Superintendencia de Servicios Financieros del Banco Central del Uruguay en caso de que la institución no le dé respuesta o esta sea insatisfactoria.</p>


              </div>
            }

            {activeStep == 2 && !loading &&

              <>

                <div className="c-viewport__step c-viewport__step--centred o-section">

                  {title ? <h2 className={`c-title c-title--small c-title--blue c-second-title ${showCrediton && 'c-title--red'} `}>{title}</h2> :
                    <h2 className={`c-title c-title--small c-title--blue c-second-title ${showCrediton && 'c-title--red'} `}>Gracias por tu solicitud!</h2>}
                  {mensaje && <span className="c-form__text c-text c-message-text">{mensaje}</span>}

                  {wpp &&
                    <a href={`${showCrediton ? 'https://wa.me/59891200055' : 'https://wa.me/59899270679'}`} target="_blank" className="c-btn c-form__btn c-send__btn c-wsp__btn"><span className={`${showCrediton ? 'c-btn__icon' : 'c-btn--icon'} icon-wsp`} />Ir a WhatsApp</a>}


                  <Link to={`/`} className="c-form__link c-text" >Volver al inicio</Link>

                </div>

              </>
            }

          </div>





        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query SolicitudQuery2 {
            prismic {
          allMetadatas  {
            edges {
          node {
            ...MetadataFragment
          }
          }
        },
    allFooters {
            edges {
          node {
            ...FooterFragment
          }
          }
        },
    allInformacion_financieras {
            edges {
          node {
            ...InformacionFinancieraFragment
          }
          }
        } 
      }
    }
    `


export default Reclamos;

